import { GenderEnum, User } from './user.model';
import { Sport } from '../sports/sport.model';
import { QuestionTypeSportResponse } from '../sports/question-type-sport-response.model';
import { Subsidiary } from './subsidiary.model';
import { TwoListsSelectModel } from '../../components/two-lists-select/two-lists-select.model';

export class Athlete extends TwoListsSelectModel {
  public address: string;
  public codePostal: string;
  public ville: string;
  public pays: string;
  public fis_code: string;
  public mobile: string;
  public team: string;
  public gender: GenderEnum;
  public height: number | null = null;
  public weight: number | null = null;
  public shirtSize: number;
  public pantsSize: string;
  public shoesSize: number | null;
  public headSize: number | null;
  public birthdate: Date;
  public rightFootLength: number | null;
  public rightFootMetaWidth: number | null;
  public rightFootInStepPerimeter: number | null;
  public rightFrontPerimeter: number | null;
  public rightParisPoint: number | null;
  public rightMondopointLength: number | null;
  public leftFootLength: number | null;
  public leftFootMetaWidth: number | null;
  public leftFootInStepPerimeter: number | null;
  public leftFrontPerimeter: number | null;
  public leftParisPoint: number | null;
  public leftMondopointLength: number | null;
  public userId: number | null;
  public userFirstName: string = '';
  public userLastName: string = '';
  public userLogin: string;
  public userReferent: string;
  public sports: Sport[] = [];
  public sportsResponses: QuestionTypeSportResponse[];
  public subsidiary: Subsidiary;
  public selected: boolean; // front only
  private _user: User;

  get user() {
    return this._user;
  }

  set user(user: User) {
    this._user = Object.assign(new User(), user);
    this.userId = user.id;
    this.userFirstName = user.firstName;
    this.userLastName = user.lastName;
    this.userLogin = user.login;
    if (user.sponsor) this.userReferent = user.sponsor;
  }

  override get title(): string {
    return this.userFirstName + ' ' + this.userLastName;
  }
}
