import { Injectable } from '@angular/core';
import { AuthoritiesEnum, User } from 'src/app/models/users/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthorityVerificationService {
  public static getHighLevelAuthority(authorities: string[]): AuthoritiesEnum {
    if (authorities == null || authorities.length == 0) {
      return AuthoritiesEnum.TESTER;
    }

    if (authorities.indexOf(AuthoritiesEnum.ADMIN) > -1) {
      return AuthoritiesEnum.ADMIN;
    } else if (authorities.indexOf(AuthoritiesEnum.S2A_HQ) > -1) {
      return AuthoritiesEnum.S2A_HQ;
    } else if (authorities.indexOf(AuthoritiesEnum.S2A_LOCAL) > -1) {
      return AuthoritiesEnum.S2A_LOCAL;
    } else if (authorities.indexOf(AuthoritiesEnum.TESTING_ADMIN) > -1) {
      return AuthoritiesEnum.TESTING_ADMIN;
    } else if (authorities.indexOf(AuthoritiesEnum.ATHLETE) > -1) {
      return AuthoritiesEnum.ATHLETE;
    } else if (authorities.indexOf(AuthoritiesEnum.MANAGER) > -1) {
      return AuthoritiesEnum.MANAGER;
    } else if (authorities.indexOf(AuthoritiesEnum.TESTER) > -1) {
      return AuthoritiesEnum.TESTER;
    }

    return AuthoritiesEnum.TESTER;
  }

  private static userParameterIsNotNull(user: User) {
    return user != null && user.authorities != null;
  }
  public hasAdminRole(user: User): boolean {
    return AuthorityVerificationService.userParameterIsNotNull(user)
      ? user.authorities.indexOf(AuthoritiesEnum.ADMIN) > -1
      : false;
  }

  public hasS2AHQRole(user: User): boolean {
    return (
      this.hasAdminRole(user) ||
      (AuthorityVerificationService.userParameterIsNotNull(user)
        ? user.authorities.indexOf(AuthoritiesEnum.S2A_HQ) > -1
        : false)
    );
  }

  public hasTestingAdminRole(user: User): boolean {
    return (
      this.hasS2AHQRole(user) ||
      (AuthorityVerificationService.userParameterIsNotNull(user)
        ? user.authorities.indexOf(AuthoritiesEnum.TESTING_ADMIN) > -1
        : false)
    );
  }

  public hasS2ALocalRole(user: User): boolean {
    return (
      this.hasS2AHQRole(user) ||
      (AuthorityVerificationService.userParameterIsNotNull(user)
        ? user.authorities.indexOf(AuthoritiesEnum.S2A_LOCAL) > -1
        : false)
    );
  }

  public hasTestingManagerRole(user: User): boolean {
    return (
      this.hasTestingAdminRole(user) ||
      (AuthorityVerificationService.userParameterIsNotNull(user)
        ? user.authorities.indexOf(AuthoritiesEnum.MANAGER) > -1
        : false)
    );
  }

  public hasAthleteRole(user: User): boolean {
    return (
      this.hasS2ALocalRole(user) ||
      (AuthorityVerificationService.userParameterIsNotNull(user)
        ? user.authorities.indexOf(AuthoritiesEnum.ATHLETE) > -1
        : false)
    );
  }

  public hasTesterRole(user: User): boolean {
    return (
      this.hasTestingManagerRole(user) ||
      this.hasAthleteRole(user) ||
      (AuthorityVerificationService.userParameterIsNotNull(user)
        ? user.authorities.indexOf(AuthoritiesEnum.TESTER) > -1
        : false)
    );
  }

  public getAuthoritiesAboveFrom(authority: AuthoritiesEnum): AuthoritiesEnum[] {
    switch (authority) {
      case AuthoritiesEnum.ADMIN:
        return [AuthoritiesEnum.ADMIN];
      case AuthoritiesEnum.S2A_HQ:
        return [AuthoritiesEnum.ADMIN, AuthoritiesEnum.S2A_HQ];
      case AuthoritiesEnum.S2A_LOCAL:
        return [AuthoritiesEnum.ADMIN, AuthoritiesEnum.S2A_HQ, AuthoritiesEnum.S2A_LOCAL];
      case AuthoritiesEnum.ATHLETE:
        return [AuthoritiesEnum.ADMIN, AuthoritiesEnum.S2A_HQ, AuthoritiesEnum.S2A_LOCAL, AuthoritiesEnum.ATHLETE];
      case AuthoritiesEnum.TESTING_ADMIN:
        return [AuthoritiesEnum.ADMIN, AuthoritiesEnum.S2A_HQ, AuthoritiesEnum.TESTING_ADMIN];
      case AuthoritiesEnum.MANAGER:
        return [AuthoritiesEnum.ADMIN, AuthoritiesEnum.S2A_HQ, AuthoritiesEnum.TESTING_ADMIN, AuthoritiesEnum.MANAGER];
      case AuthoritiesEnum.TESTER:
        return [
          AuthoritiesEnum.ADMIN,
          AuthoritiesEnum.S2A_HQ,
          AuthoritiesEnum.TESTING_ADMIN,
          AuthoritiesEnum.MANAGER,
          AuthoritiesEnum.S2A_LOCAL,
          AuthoritiesEnum.ATHLETE,
          AuthoritiesEnum.TESTER,
        ];
      default:
        return [];
    }
  }

  public getAuthoritiesBelowFrom(authority: AuthoritiesEnum): AuthoritiesEnum[] {
    switch (authority) {
      case AuthoritiesEnum.ADMIN:
        return [
          AuthoritiesEnum.S2A_HQ,
          AuthoritiesEnum.TESTING_ADMIN,
          AuthoritiesEnum.MANAGER,
          AuthoritiesEnum.S2A_LOCAL,
          AuthoritiesEnum.ATHLETE,
          AuthoritiesEnum.TESTER,
        ];
      case AuthoritiesEnum.S2A_HQ:
        return [
          AuthoritiesEnum.S2A_LOCAL,
          AuthoritiesEnum.TESTING_ADMIN,
          AuthoritiesEnum.MANAGER,
          AuthoritiesEnum.ATHLETE,
          AuthoritiesEnum.TESTER,
        ];
      case AuthoritiesEnum.S2A_LOCAL:
        return [AuthoritiesEnum.ATHLETE, AuthoritiesEnum.TESTER];
      case AuthoritiesEnum.ATHLETE:
        return [AuthoritiesEnum.TESTER];
      case AuthoritiesEnum.TESTING_ADMIN:
        return [AuthoritiesEnum.MANAGER, AuthoritiesEnum.TESTER];
      case AuthoritiesEnum.MANAGER:
        return [AuthoritiesEnum.TESTER];
      case AuthoritiesEnum.TESTER:
        return [];
      default:
        return [];
    }
  }

  public isRoleGreaterThanOtherRole(user1: User, user2: User) {
    // Ce que veux dire cette ligne :
    // Si la liste des autorités en dessous (et égal) de l'autorité maximale de l'utilisateur 1
    // contient l'autorité maximale de l'utilisateur 2,
    // ET que les deux autorités maximales des deux utilisateurs ne sont pas égaux,
    // c'est que le role de l'utilsateur 1 est au dessus du role de l'utilisateur 2
    return (
      user1.authorities.indexOf(AuthorityVerificationService.getHighLevelAuthority(user2.authorities)) > -1 &&
      AuthorityVerificationService.getHighLevelAuthority(user1.authorities) !=
        AuthorityVerificationService.getHighLevelAuthority(user2.authorities)
    );
  }
}
