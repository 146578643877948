import { Athlete } from './athlete.model';
import { Manager } from './manager.model';
import { TwoListsSelectModel } from '../../components/two-lists-select/two-lists-select.model';
import { AuthorityVerificationService } from '../../services/authority-verification/authority-verification.service';

export class User extends TwoListsSelectModel {
  public login: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public activated: boolean;
  public langKey: string;
  public firstConnection: boolean;
  public authorities: AuthoritiesEnum[];
  public createdBy: string;
  public createdDate: Date;
  public lastModifiedBy: string;
  public lastModifiedDate: Date;
  public status: UserStatus;
  public sponsor: string | null;
  public imageUrl: string;
  public hasRequest: boolean;
  public hasDelRequest: boolean;
  public anonymous: boolean;
  public anonimisationDate: Date;
  public athleteId: number;
  public managerId: number;
  public acceptCgu: boolean;
  public acceptTerms: boolean;
  public acceptConsent: boolean;
  private _athlete: Athlete;
  private _manager: Manager;

  override get title(): string {
    return this.firstName + ' ' + this.lastName;
  }

  override set title(value: [string, string]) {
    this.firstName = value[0];
    this.lastName = value[1];
  }
  public get athlete(): Athlete | null {
    return this._athlete;
  }

  public set athlete(athlete: Athlete) {
    this._athlete = Object.assign(new Athlete(), athlete);
    this._athlete.userId = this.id;
  }

  public get manager(): Manager | null {
    return this._manager;
  }

  public set manager(manager: Manager) {
    this._manager = manager;
    if (this._manager) this._manager.userId = this.id;
  }

  public get emailHandler(): string {
    return this.email;
  }

  public set emailHandler(email: string) {
    this.email = email;
    this.login = email;
  }

  public get firstAuthority(): AuthoritiesEnum | null {
    if (this.authorities != null && this.authorities.length > 0) {
      return AuthorityVerificationService.getHighLevelAuthority(this.authorities);
    } else {
      return null;
    }
  }

  public set firstAuthority(authority: AuthoritiesEnum) {
    this.authorities = [authority];
  }

  filterBySearchTerm(searchTerm: string) {
    const searchTermToLower = searchTerm.toLowerCase();

    let condition =
      this.langKey.toLowerCase().indexOf(searchTermToLower) > -1 ||
      this.firstName.toLowerCase().indexOf(searchTermToLower) > -1 ||
      this.lastName.toLowerCase().indexOf(searchTermToLower) > -1 ||
      (this.firstName + ' ' + this.lastName).toLowerCase().indexOf(searchTermToLower) > -1 ||
      this.email.toLowerCase().indexOf(searchTermToLower) > -1;

    if (this.athlete != null && this.athlete.subsidiary != null) {
      condition = condition || this.athlete.subsidiary.title.toLowerCase().indexOf(searchTermToLower) > -1;
    }

    if (this.athlete != null && this.athlete.sports != null) {
      condition =
        condition ||
        this.athlete.sports
          .map((sport) => sport.name)
          .toString()
          .toLowerCase()
          .indexOf(searchTermToLower) > -1;
    }

    if (this.manager != null && this.manager.categories != null) {
      condition =
        condition ||
        this.manager.categories
          .map((category) => category.title)
          .toString()
          .toLowerCase()
          .indexOf(searchTermToLower) > -1;
    }

    return condition;
  }
}

export enum GenderEnum {
  MALE = 'male',
  FEMALE = 'female',
}

export enum UserStatus {
  NONE = 'NONE',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum AuthoritiesEnum {
  ADMIN = 'ROLE_ADMIN',
  TESTER = 'ROLE_TESTER',
  ATHLETE = 'ROLE_ATHLETE',
  MANAGER = 'ROLE_MANAGER',
  TESTING_ADMIN = 'ROLE_TESTING_ADMIN',
  S2A_HQ = 'ROLE_S2A_HQ',
  S2A_LOCAL = 'ROLE_S2A_LOCAL',
}
