export abstract class TwoListsSelectModel {
  public id: number | null;
  protected _title: string;
  protected _subtitle: string;
  public order: number;
  public alias: string;

  public get title() {
    return this._title;
  }

  public set title(value: string) {
    this.title = value;
  }

  public get subtitle() {
    return this._subtitle;
  }

  public set subtitle(value: string) {
    this.subtitle = value;
  }

  get keepUnchoosen(): boolean {
    return false;
  }
}
